import { createLazyFileRoute } from "@tanstack/react-router";
import { VpcView } from "../../../../../../modules/network/vpc/VpcView.tsx";

export const Route = createLazyFileRoute("/_main/network/vpc/$location/$uuid/")({
    component: Component,
});

function Component() {
    const { uuid, location } = Route.useParams();
    // NB: `key` prop ensures that the component state is reset when the route changes (to prevent eg. `isOpen` etc. states from remaining the same)
    return <VpcView key={uuid} location={location} vpcId={uuid} />;
}
