import type { VpcUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { mapFromEntries } from "@warrenio/utils/collections/maps";
import { uniqueBy } from "remeda";
import { Badge } from "../../../components/Badge.tsx";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import ContentPane from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { Separator } from "../../../components/Separator.tsx";
import { Spacer } from "../../../components/Spacer.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { WTooltip } from "../../../components/WTooltip.tsx";
import { WButton } from "../../../components/button/WButton.tsx";
import { MaskIcon } from "../../../components/icon/MaskIcon.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { ContentLoadingSuspense } from "../../../components/loading/Loading.tsx";
import { DeleteModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableName } from "../../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { WTable, WTableBody } from "../../../components/table/WTable.tsx";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { useQueryResultAtom, useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { getResourceLink } from "../../api/resourceTypes.tsx";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { LocationDetail } from "../../location/LocationDetail.tsx";
import { ResourceStatusBadge } from "../ResourceStatusBadge.tsx";
import { ipQueryAtom } from "../ipAddress/apiOperations.ts";
import { getDisplayName } from "../ipAddress/resourceId.ts";
import { lbQueryAtom } from "../loadbalancer/apiOperations.ts";
import {
    deleteVpcNetworkMutation,
    modifyVpcNetworkMutation,
    setDefaultVpcNetworkMutation,
    vpcQueryAtom,
    type VpcWithType,
} from "./apiOperations.ts";
import { vpcVmTargetsAtom } from "./members.ts";

export function VpcView({ location, vpcId }: { location: string; vpcId: string }) {
    //#region Hooks
    const deleteMutation = useStandardMutation(deleteVpcNetworkMutation);
    const setDefaultMutation = useStandardMutation(setDefaultVpcNetworkMutation);
    const modifyMutation = useStandardMutation(modifyVpcNetworkMutation);

    const data = useSuspenseQueryAtom(vpcQueryAtom(location));

    const vpc = useDeletableResource(() => getResourceById(data, vpcId, "vpc"), deleteMutation);
    //#endregion

    if (vpc === undefined) {
        return <NavigateAfterDelete />;
    }

    const { $type, name, subnet, created_at, uuid, subnet_ipv6, resources_count, is_default } = vpc;

    async function onDelete() {
        await deleteMutation.mutateAsync({ location, uuid: vpcId });
    }

    async function setDefault() {
        await setDefaultMutation.mutateAsync({ location, uuid: vpcId });
    }

    async function onModify({ name: newName }: VpcUpdateBody) {
        newName = newName.trim();
        if (newName && name !== newName) {
            await modifyMutation.mutateAsync({
                body: { name: newName },
                location,
                uuid,
            });
        }
    }

    return (
        <>
            <Toolbar>
                <WButton
                    color="primary"
                    size="bar"
                    variant="ghost"
                    action={async () => await setDefault()}
                    isDisabled={is_default}
                    icon="jp-icon-checkmark"
                >
                    Set Default
                </WButton>

                <DeleteModal
                    title="Delete VPC Network"
                    isDisabled={!!resources_count || is_default}
                    modalAction={async () => await onDelete()}
                >
                    Deleting the VPC Network "{name}" will remove it from your account.
                </DeleteModal>
            </Toolbar>

            <ContentPane>
                <h1 className="font-size-heading">
                    {name ? name : ""}
                    {is_default && (
                        <span className="inline-flex items-center font-size-small pl-4">
                            <Badge inHeader noDot>
                                <MaskIcon className="jp-icon-checkmark size-0.75rem" />
                            </Badge>
                            <span className="pl-1">Default</span>
                        </span>
                    )}
                </h1>

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableName
                            value={name ?? ""}
                            isRequired
                            onChange={async (name) => await onModify({ name })}
                        />
                        <DetailsTableRow title="Type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>
                        <DetailsTableRow title="IPv4 range:">
                            <ClipBoardTooltip>{subnet}</ClipBoardTooltip>
                        </DetailsTableRow>
                        <DetailsTableRow title="IPv6 range:">
                            {!!subnet_ipv6 && <ClipBoardTooltip>{subnet_ipv6}</ClipBoardTooltip>}
                        </DetailsTableRow>
                        <DetailsTableRow title="Resources:">{resources_count}</DetailsTableRow>
                    </DetailsTable>

                    <Spacer />

                    <DetailsTable>
                        <DetailsTableRow title="UUID:">
                            <ClipBoardTooltip text={uuid}>{uuid}</ClipBoardTooltip>
                        </DetailsTableRow>
                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <LocationDetail slug={location} />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>

            <Separator />

            <ContentPane>
                <h2 className="font-size-subtitle">Resources</h2>
                <ContentLoadingSuspense>
                    <ResourcesTable obj={vpc} />
                </ContentLoadingSuspense>
            </ContentPane>
        </>
    );
}

function ResourcesTable({ obj }: { obj: VpcWithType }) {
    const { location } = obj;
    const data = useSuspenseQueryAtom(ipQueryAtom(location));

    const { data: vms } = useQueryResultAtom(vpcVmTargetsAtom(location));
    const balancers = useSuspenseQueryAtom(lbQueryAtom(location));

    // TODO: Move this "index" into the query result so it can be used elsewhere?
    const assignedToIps = mapFromEntries(data.values(), (ip) => [ip.assigned_to, ip]);

    const vmResources = uniqueBy(
        filterNulls(obj.vm_uuids.map((uuid) => vms?.get(uuid))),
        // Since a service can have multiple virtual machines, filter out "duplicates" (ie. same service, different VMs)
        (r) => r.uuid,
    );
    const balancerResources = [...balancers.values()].filter((b) => b.network_uuid === obj.uuid);

    const items = [...vmResources, ...balancerResources];

    return (
        <WTable>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Public IPv4</th>
                    <th>Status</th>
                    <th>Last Modified</th>
                </tr>
            </thead>
            <WTableBody>
                {items.map((item) => {
                    const ipAddress = assignedToIps.get(item.uuid);

                    const action = getResourceLink(item);

                    const address = ipAddress?.address;
                    return (
                        <tr key={item.uuid}>
                            <td>
                                <WTooltip text="View">
                                    <WButton color="primary" size="xs" variant="ghost" action={action}>
                                        {getDisplayName(item)}
                                    </WButton>
                                </WTooltip>
                            </td>
                            <td>
                                <ResourceWithIcon type={item.$type} />
                            </td>
                            <td>{address ? <ClipBoardTooltip>{address}</ClipBoardTooltip> : "-"}</td>
                            <td>
                                {item.$type === "virtual_machine" || item.$type === "managed_service" ? (
                                    <ResourceStatusBadge item={item} />
                                ) : null}
                            </td>
                            <td>{item.updated_at ? <LongDate date={item.updated_at} /> : null}</td>
                        </tr>
                    );
                })}
            </WTableBody>
        </WTable>
    );
}
